import { useState, useEffect } from "react"
import type { ComponentType } from "react"
import { motion, useMotionValue, useSpring } from "framer-motion"

export const withCursorFollow = (Component): ComponentType => {
    return (props) => {
        const x = useMotionValue(0)
        const y = useMotionValue(0)
        const [componentBounds, setComponentBounds] = useState(null)

        const springConfig = { damping: 100, stiffness: 400 }
        const springX = useSpring(x, springConfig)
        const springY = useSpring(y, springConfig)

        useEffect(() => {
            const calculateDistance = (e) => {
                if (componentBounds) {
                    const centerX =
                        componentBounds.left + componentBounds.width / 2
                    const centerY =
                        componentBounds.top + componentBounds.height / 2
                    const distanceX = e.clientX - centerX
                    const distanceY = e.clientY - centerY
                    const maxDistance = 60

                    if (
                        Math.abs(distanceX) < maxDistance &&
                        Math.abs(distanceY) < maxDistance
                    ) {
                        const proximityFactor =
                            1 -
                            Math.max(Math.abs(distanceX), Math.abs(distanceY)) /
                                maxDistance
                        x.set(distanceX * proximityFactor * 0.4)
                        y.set(distanceY * proximityFactor * 0.4)
                    } else {
                        x.set(0)
                        y.set(0)
                    }
                }
            }

            const handleMouseMove = (e) => {
                calculateDistance(e)
            }

            document.addEventListener("mousemove", handleMouseMove)

            return () => {
                document.removeEventListener("mousemove", handleMouseMove)
            }
        }, [componentBounds])

        return (
            <motion.div
                ref={(el) => {
                    if (el && !componentBounds) {
                        setComponentBounds(el.getBoundingClientRect())
                    }
                }}
                style={{
                    x: springX,
                    y: springY,
                }}
            >
                <Component {...props} />
            </motion.div>
        )
    }
}
